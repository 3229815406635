import React, { useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import DatePicker from 'src/components/date-picker/date-picker';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import useDateRange from 'src/components/rankings/utils/useDateRange';
import { Body, H3 } from 'src/components/typography/typography';
import { getOneDayBefore, getOneYearAgo } from 'src/utils/helper/dates';

interface NewManualRankingRunDialogProps {
  title: string;
  onCancel: () => void;
  onSubmit: (formData: any) => void;
  loading: boolean;
  error?: string;
}

const NewManualRankingRunDialog: React.FC<NewManualRankingRunDialogProps> = ({
  title,
  onCancel,
  onSubmit,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control, setValue } = useForm();

  const handleCreateNewRankList = useCallback(
    (formData) => {
      onSubmit(formData);
    },
    [onSubmit],
  );

  // By default, set the start and end date to construct a list across the last 12 months
  useDateRange(setValue, getOneYearAgo, getOneDayBefore);

  return (
    <form onSubmit={handleSubmit(handleCreateNewRankList)} data-testid="new-ranking-run-form">
      <CustomGrid container direction="row" spacing={{ margins: { lg: 'bottom' } }}>
        <H3>{title}</H3>
        <CustomGrid item xs spacing={{ margins: { sm: 'right' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period start')}
          </Body>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => <DatePicker selected={field.value} onChange={(date) => field.onChange(date)} />}
          />
        </CustomGrid>
        <CustomGrid item xs spacing={{ margins: { sm: 'left' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period end')}
          </Body>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => <DatePicker selected={field.value} onChange={(date) => field.onChange(date)} />}
          />
        </CustomGrid>
      </CustomGrid>
      <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }} style={{ marginTop: 150 }}>
        <Button onClick={onCancel} type="button" level="tertiary">
          {t('cancel')}
        </Button>
        <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={loading}>
          {t('save run')}
        </Button>
      </CustomGrid>
      <CustomGrid style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {error && <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={t('generate ranklist error')} />}
      </CustomGrid>
    </form>
  );
};

export default NewManualRankingRunDialog;
