import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';
import Icon from 'src/components/icon/icon';
import { NewRunDialog } from 'src/components/new-run-dialog/new-run-dialog';
import Panel from 'src/components/panel/panel';
import { ScheduleRankingRuns } from 'src/components/schedule-ranking-runs/schedule-ranking-runs';
import { ScheduleRunsDialog } from 'src/components/schedule-runs-dialog/schedule-runs-dialog';
import { transformSortDirection, useControlledQuery } from 'src/components/table-controls/table-controls';
import { Body, H2 } from 'src/components/typography/typography';
import { PublishStatusEnum } from 'src/components/usta-ranking-filters/usta-ranking-filters';
import {
  getAgeRestrictionLabel,
  getDivisionTypeLabel,
  getListType,
  getRanklistJobsQueryParams,
  isFormatCombined,
  nullGuard,
} from 'src/components/usta-rankings/helpers';
import { GET_RANK_LISTS_PAGINATED, GET_RANKLIST_RUN_GROUPS } from 'src/components/usta-rankings/usta-ranking-queries';
import {
  GetRankListsPaginated,
  GetRankListsPaginated_ranklistsPaginated_items as RanklistsPaginatedItem,
} from 'src/graphql-types/GetRankListsPaginated';
import { ListTypeEnum, MatchFormatEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { RanklistRunGroups } from 'src/graphql-types/RanklistRunGroups';
import { RankListSchedules_rankListSchedules } from 'src/graphql-types/RankListSchedules';
import { useRankListSchedule, useStopRankListSchedule } from 'src/hooks/data/rankings';
import { useOrgLevel } from 'src/utils/auth';
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name';
import { capitalize } from 'src/utils/helper/rankings';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { useColumns as useScheduleColumns } from './usta-ranklist-schedule.columns';
import { useColumns } from './usta-ranklist.columns';
import * as styles from './usta-ranklist.module.less';
import {
  displayScheduledRuns,
  getAgeRestriction,
  getFamilyAgeRestriction,
  getFiltersFromQueryParams,
} from './usta-ranklist.utils';

enum ScheduleCheckedEnum {
  IDLE,
  OFF,
  ON,
}

const USTARanklist = () => {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();
  const [newRunDialog, setNewRunDialog] = useState(false);
  const [scheduleChecked, setScheduleChecked] = useState<ScheduleCheckedEnum>(ScheduleCheckedEnum.IDLE);
  const [scheduleDialog, setScheduleDialog] = useState(false);

  const [filters, setFilters] = useState({ publishStatus: '' });

  const [query, setQuery] = useQueryParams({
    listType: StringParam,
    playerType: StringParam,
    ageRestriction: StringParam,
    gender: StringParam,
    genderModifier: StringParam,
    matchFormat: StringParam,
    matchFormatType: StringParam,
    playerLevel: StringParam,
    divisionType: StringParam,
    region: StringParam,
    latestListMadeVisible: StringParam,
    familyCategory: StringParam,
    ranklistJobs: ArrayParam,
    editScheduleId: StringParam,
  });

  const getTotalItems = useCallback((d: any) => d.ranklistsPaginated?.totalItems, []);

  const {
    data: ranklistsData,
    loading: loadingRanklists,
    controlProps,
    refetch,
  } = useControlledQuery<GetRankListsPaginated>(GET_RANK_LISTS_PAGINATED, {
    getTotalItems,
    transformVariables,
    client: meshGatewayClient,
    notifyOnNetworkStatusChange: true, // Solves refetch not triggering update
    fetchPolicy: 'network-only', // Solves refetch not triggering update
  });

  const ranklists = ranklistsData?.ranklistsPaginated?.items ?? [];

  const [stopRankListSchedule] = useStopRankListSchedule({ refetchQueries: ['RanklistRunGroups'] });

  const {
    data: ranklistSchedules,
    loading: loadingSchedules,
    refetch: refetchRanklistSchedules,
  } = useRankListSchedule();

  const activeSchedule = useMemo(
    () => ranklistSchedules?.rankListSchedules?.find((s) => !s?.disabled),
    [ranklistSchedules],
  );

  useEffect(() => {
    (async () => {
      await handleScheduleCheckChange();
    })();
  }, [activeSchedule, scheduleChecked]);

  const handleScheduleCheckChange = async () => {
    if (activeSchedule && activeSchedule?.id && scheduleChecked == ScheduleCheckedEnum.IDLE) {
      setScheduleChecked(ScheduleCheckedEnum.ON);
    }

    if (activeSchedule && activeSchedule?.id && scheduleChecked === ScheduleCheckedEnum.OFF) {
      await stopRankListSchedule({ variables: { id: activeSchedule.id } });
      await refetchRanklistSchedules?.();
    }

    if (!activeSchedule && scheduleChecked === ScheduleCheckedEnum.ON) {
      setScheduleDialog(true);
    }
  };

  // Used only to get the "Last published" value
  const { data: ranklistRunGroupsData } = useControlledQuery<RanklistRunGroups>(GET_RANKLIST_RUN_GROUPS, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
    getTotalItems: (d: RanklistRunGroups) => d.ranklistRunGroups?.totalItems,
    transformVariables: () => {
      const filters = getFiltersFromQueryParams({ query });
      const genderModifier = filters.genderModifier === 'null' ? null : filters.genderModifier;

      return {
        pageArgs: { limit: 1, skip: 0 },
        ranklistRunGroupFilter: {
          ...filters,
          genderModifier,
          ...getAgeRestriction(query.playerType, query.ageRestriction),
        },
      };
    },
  });

  const ranklistRunGroup = ranklistRunGroupsData?.ranklistRunGroups?.items?.[0];
  const getRanklistRunGroupLastPublished = () => {
    if (!ranklistRunGroup?.latestListMadeVisible) return '-';
    return t('payment date', { date: dayjs(ranklistRunGroup?.latestListMadeVisible).local() });
  };

  function transformVariables(optionsWithControls: any) {
    const { limit = 10, offset = 0, sorts = [] } = optionsWithControls ?? {};
    const [sortsObject = {}] = sorts;
    const { sortDirection = '', property = '' } = sortsObject;

    const sort = () => {
      if (sortDirection === '' || property === '') {
        return { sort: { field: 'CREATED_AT', direction: 'DESC' } }; // Return initial sorting
      } else {
        return {
          sort: {
            field: property.toUpperCase(),
            direction: transformSortDirection(sortDirection),
          },
        };
      }
    };

    const isVisible = filters.publishStatus === PublishStatusEnum.PUBLISHED;
    const visibilityFilter = filters.publishStatus ? { visible: isVisible } : {};

    const ranklistFilters = getFiltersFromQueryParams({ query });
    const genderModifier = ranklistFilters.genderModifier === 'null' ? null : ranklistFilters.genderModifier;

    const isCombined = isFormatCombined({
      listType: nullGuard(query.listType as ListTypeEnum),
      matchFormat: nullGuard(query.matchFormat as MatchFormatEnum),
      playerType: nullGuard(query.playerType as PlayerTypeEnum),
    });
    const matchFormat = isCombined ? { matchFormat: MatchFormatEnum.COMBINED } : {};

    return {
      ...sort(),
      ranklistFilter: {
        ...ranklistFilters,
        ...visibilityFilter,
        ...matchFormat,
        genderModifier,
        ...getAgeRestriction(query.playerType, query.ageRestriction),
      },
      pageArgs: {
        limit,
        skip: offset,
      },
    };
  }

  const publishFilterOptions = [
    { value: '', label: t('any published status') },
    {
      value: PublishStatusEnum.PUBLISHED,
      label: t('published'),
    },
    {
      value: PublishStatusEnum.HIDDEN,
      label: t('hidden'),
    },
  ];

  const noScheduledLists = ranklists.every((list) => !list.scheduleId);

  const cols: DataCols<RanklistsPaginatedItem> = useColumns({
    noScheduledLists,
    listType: query.listType as ListTypeEnum,
  });

  const handleEditSchedule = (scheduleId: string) => {
    setQuery({ editScheduleId: scheduleId });
    setScheduleDialog(true);
  };

  const scheduledRunsCols: DataCols<
    RankListSchedules_rankListSchedules & {
      id: string;
    }
  > = useScheduleColumns({ handleEditSchedule });

  const handleRowClick = (t: RanklistsPaginatedItem) => {
    const ranklistJobs = query.ranklistJobs;
    const ranklistJobsQueryParams = getRanklistJobsQueryParams(ranklistJobs);
    navigate(`/rankings/${t.id}?latestListMadeVisible=${query.latestListMadeVisible}&${ranklistJobsQueryParams}`);
  };

  const ranklistName = generateRanklistName(
    {
      playerType: query.playerType,
      listType: query.listType,
      ageRestriction: query.ageRestriction,
      gender: query.gender,
      genderModifier: query.genderModifier,
      matchFormat: query.matchFormat,
      matchFormatType: query.matchFormatType,
      playerLevel: query.playerLevel,
      divisionType: query.divisionType,
      familyCategory: query.familyCategory,
      region: query.region,
    } as any,
    t,
  );

  const ageGroupLabel = getAgeRestrictionLabel({
    playerType: nullGuard(query.playerType),
    ageRestriction: nullGuard(query.ageRestriction),
    divisionType: nullGuard(query.divisionType),
    playerLevel: nullGuard(query.playerLevel),
    t,
  } as any);

  const divisionTypeLabel = getDivisionTypeLabel({
    playerType: query.playerType,
    divisionType: query.divisionType,
    playerLevel: query.playerLevel,
    familyCategory: query.familyCategory,
    t,
  } as any);

  const handleBackClick = () => {
    const ranklistJobs = query.ranklistJobs;
    const ranklistJobsQueryParams = getRanklistJobsQueryParams(ranklistJobs);
    navigate(`/rankings?${ranklistJobsQueryParams}`);
  };

  const handleSetScheduleChecked = (checked: boolean) => {
    if (checked) setScheduleChecked(ScheduleCheckedEnum.ON);
    if (!checked) setScheduleChecked(ScheduleCheckedEnum.OFF);
  };

  return (
    <div>
      <button onClick={handleBackClick} className={styles.backButton}>
        <Icon name="sm-left" className={styles.arrowIcon} />
        {t('back to rankings')}
      </button>
      <Panel extendedPadding>
        <Grid container>
          <Grid container item xs alignItems="center" justifyContent="space-between">
            <H2 spacing={{ margins: { sm: 'right' } }} noMargin>
              {ranklistName}
            </H2>
            {isNational && displayScheduledRuns(query.listType as ListTypeEnum) && (
              <ScheduleRankingRuns
                checked={scheduleChecked === ScheduleCheckedEnum.ON}
                setChecked={handleSetScheduleChecked}
                disabled={loadingSchedules}
              />
            )}
          </Grid>
        </Grid>
        <div className={styles.divider} />
        <Grid container>
          <CustomGrid spacing={{ margins: { lg: 'right' } }}>
            <Body size="md" bold>
              {t('player category')}
            </Body>
            <Body size="md">{capitalize(query.playerType)}</Body>
          </CustomGrid>

          <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
            <Body size="md" bold>
              {t('age group')}
            </Body>
            <Body size="md">{ageGroupLabel}</Body>
          </CustomGrid>

          <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
            <Body size="md" bold>
              {t('division type')}
            </Body>
            <Body size="md">{divisionTypeLabel}</Body>
          </CustomGrid>

          <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
            <Body size="md" bold>
              {t('list type')}
            </Body>
            <Body size="md">{getListType(query.listType as ListTypeEnum, t)}</Body>
          </CustomGrid>

          <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
            <Body size="md" bold>
              {t('last published')}
            </Body>
            <Body size="md">{getRanklistRunGroupLastPublished()}</Body>
          </CustomGrid>
        </Grid>
      </Panel>
      <Panel extendedPadding>
        {displayScheduledRuns(query.listType as ListTypeEnum) && (
          <CustomGrid container hide={!activeSchedule} spacing={{ margins: { lg: 'bottom' } }}>
            <Body size="xl" bold spacing={{ margins: { sm: 'bottom' } }}>
              {t('scheduled runs')}
            </Body>
            <AdminTable
              data={activeSchedule ? [activeSchedule] : ([] as any)}
              loading={loadingSchedules}
              columns={scheduledRunsCols}
              disableRowsPerPage
              hideTopPaginationInfo
              rowProps={{ scheduleRowStyle: true }}
              spacing={{ margins: { md: 'bottom' } }}
            />
          </CustomGrid>
        )}
        <CustomGrid container>
          <Body size="xl" bold spacing={{ margins: { sm: 'bottom' } }}>
            {query.listType === ListTypeEnum.L2_QUALIFIER
              ? `${t('level 2 qualifier')} ${t('lists')}`
              : t('completed runs')}
          </Body>
        </CustomGrid>
        <CustomGrid container justifyContent="space-between" spacing={{ margins: { md: 'bottom' } }}>
          <Dropdown options={publishFilterOptions} onSelect={(o) => setFilters({ publishStatus: o.value })} />
          <Button hide={!isNational} onClick={() => setNewRunDialog(true)}>
            {query.listType === ListTypeEnum.L2_QUALIFIER ? t('add manual list') : t('add manual run')}
          </Button>
        </CustomGrid>
        <AdminTable
          data={ranklists}
          loading={loadingRanklists}
          columns={cols}
          controls={controlProps}
          onRowClick={handleRowClick}
          filters={filters}
          hideTopPaginationInfo
        />
      </Panel>
      <CustomDialog
        title={query.listType === ListTypeEnum.L2_QUALIFIER ? t('add new list') : t('add new run')}
        open={newRunDialog}
        hideX
        content={<NewRunDialog title={ranklistName} setDialog={setNewRunDialog} refetch={refetch} />}
        onClose={() => setNewRunDialog(false)}
      />
      <CustomDialog
        title={t('scheduled runs')}
        open={scheduleDialog}
        hideX
        overridePosition={{ width: '40%', margin: '0 auto' }}
        content={
          <ScheduleRunsDialog
            setDialog={setScheduleDialog}
            setScheduleChecked={handleSetScheduleChecked}
            refetch={refetchRanklistSchedules}
          />
        }
        onClose={() => setScheduleDialog(false)}
      />
    </div>
  );
};

export default USTARanklist;
