import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import Icon from 'src/components/icon/icon';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import { GetRankListsPaginated_ranklistsPaginated_items as RanklistsPaginatedItem } from 'src/graphql-types/GetRankListsPaginated';
import { ListTypeEnum, RankListSortFieldsEnum } from 'src/graphql-types/globalRankingTypes';

import * as styles from './usta-ranklist.module.less';
import { getRankingPeriod } from './usta-ranklist.utils';

interface ColumnsProps {
  noScheduledLists?: boolean;
  listType: ListTypeEnum | undefined;
}

export function useColumns({ noScheduledLists, listType }: ColumnsProps) {
  const { t } = useTranslation();

  const columns: DataCols<RanklistsPaginatedItem> = [
    {
      key: 'scheduled',
      title: ' ',
      getValue: (m) => {
        if (!m.scheduleId) return null;
        return <Icon name="sm-clock-grey" spacing={{ margins: { sm: 'left' } }} />;
      },
      noPadding: true,
      sort: false,
      hidden: noScheduledLists, // Hide if no run was scheduled
    },
    {
      key: RankListSortFieldsEnum.CREATED_AT,
      title: t('run'),
      getValue: (m) => (
        <button className={styles.runButton}>
          {t('payment date', {
            date: dayjs(m?.createdAt).local(),
          })}
        </button>
      ),
    },
    {
      key: RankListSortFieldsEnum.DATE_RANGE_START,
      title: t('ranking period'),
      getValue: (m) => {
        const { start, end } = m?.dateRange ?? {};
        return getRankingPeriod({ start, end, t });
      },
      hidden: [ListTypeEnum.L2_QUALIFIER, ListTypeEnum.ELIGIBILITY].includes(listType ?? ListTypeEnum.STANDING),
    },
    {
      key: 'YEAR',
      title: t('year'),
      getValue: (m) => m.year,
      hidden: listType !== ListTypeEnum.ELIGIBILITY,
    },
    {
      key: RankListSortFieldsEnum.ITEM_COUNT,
      title: t('no of players'),
      getValue: (m) => m.itemCount,
    },
    {
      key: RankListSortFieldsEnum.VISIBLE,
      title: t('publish status'),
      getValue: (m) => {
        const status = m.visible ? { text: t('published'), color: 'success' } : { text: t('hidden'), color: 'warning' };

        return <StatusLabel variety={status.color as LabelVariety}>{status.text}</StatusLabel>;
      },
    },
    {
      key: RankListSortFieldsEnum.UPDATED_AT,
      title: t('last published'),
      getValue: (m) => {
        if (!m?.visible) return '-';
        return t('payment date', {
          date: dayjs(m?.updatedAt).local(),
        });
      },
    },
  ];

  return columns;
}
